import { Controller } from "@hotwired/stimulus"

export class DirectorController extends Controller {

    connect() {
        // connect Account Manager to a Director
        this.account_managers_directors_autocomplete();
        this.directors_autocomplete();
    }

    account_managers_directors_autocomplete() {
        $("#account_managers_directors").autocomplete({
            source: "/admin/account_managers/active_account_managers",
            minLength: 3,
            select: $.proxy(this._select_account_manager, this)
        })
            .autocomplete('instance')._renderItem = function(ul, item) {
            const account_manager_name = `${ item.name }`;
            return $('<li>')
                .append(account_manager_name)
                .appendTo(ul);
        }
    }

    directors_autocomplete() {
        $("#directors").autocomplete({
            source: "/admin/directors/active_directors",
            minLength: 3,
            select: $.proxy(this._select_director, this)
        })
            .autocomplete('instance')._renderItem = function(ul, item) {
            const director_name = `${ item.first_name } ${ item.last_name === null ? '' : item.last_name }`;
            return $('<li>')
                .append(director_name)
                .appendTo(ul);
        }
    }

    _select_account_manager(e, ui) {
        const account_manager_name = `${ ui.item.name }`;
        $("#account_managers_directors").val(account_manager_name);
        $("#account_manager_id").val(ui.item.id);
        return false;
    }

    _select_director(e, ui) {
        const director_name = `${ ui.item.first_name } ${ ui.item.last_name === null ? '' : ui.item.last_name }`;
        $("#directors").val(director_name);
        $("#director_id").val(ui.item.id);
        return false;
    }
}
