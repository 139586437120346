import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="account-manager-metabase-dashboard"
export default class extends Controller {
  connect() {
    $(document).ready(function(){
      $("#account_manager_metabase_dashboard").parent().removeClass("offset-md-2");
    });
    this.setup_loader();
    this.merchants_autocomplete();
  }

  setup_loader() {
    const iframeEle = document.getElementById('account_manager_metabase_iframe');
    const loadingEle = document.getElementById('account_manager_metabase_iframe_loading');
    iframeEle.addEventListener('load', function () {
      // Hide the loading indicator
      loadingEle.style.display = 'none';
      // Bring the iframe back
      iframeEle.style.opacity = 1;
      // Display the form
      $("#metabase_filter_form").show();

    });
  }

  merchants_autocomplete() {
    $("#merchant").autocomplete({
      source: "/admin/merchants/active_merchants",
      minLength: 3,
      select: $.proxy(this._select_merchant, this)
    })
        .autocomplete('instance')._renderItem = function(ul, item) {
      return $('<li>')
          .append(`${item.name} - ${item.merchant_number}`)
          .appendTo(ul);
    }
  }

  _select_merchant(e, ui) {
    $("#merchant").val(`${ui.item.name} - ${ui.item.merchant_number}`);
    return false;
  }
}
