import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="unassigned-merchants"
export default class extends Controller {
  connect() {
    $(document).ready(function(){
      $("#unassigned_merchants_table").parent().removeClass("offset-md-2");
    });
  }
}
